import { Locales, THEME, TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react'
import getBattery from 'api/get-battery'
import getStone from 'api/get-stone'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import PageWrapper from 'UI/PageWrapper'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'
import tg from 'utils/tg'
import BoostStones from './components/BoostStones'

type Props = {}

const Boost = (props: Props) => {
  const {user,setModal,updateUserInfo,locale} = useContext(appContext)
  const [tab, settab] = useState(1)
  const [tonConnectUI, setOptions] = useTonConnectUI();

  const handleStone = async () => {
    const res = await getStone()
    if(!res?.result) {
      tg.HapticFeedback.notificationOccurred('error')
      tg.showAlert('Что-то пошло не так')
      return
    } else {
      await updateUserInfo()
      toast('Вы использовали заряд камня!',{icon:(
        <img src="img/boost-icon2.png" alt="" className='animate-icon'/>
      )
      })
    }
  }
  useEffect(() => {
    setOptions({
      language:(locale?.code || 'en') as Locales,
      uiPreferences: {
        theme: THEME.DARK,
      },
    });
  }, []);
  if(!locale) return null
  const text = locale.boost
  return (
   <PageWrapper className='m-page'>
<div className="boost _tabs-parent">
      <div className="container">
        <div className="boost-balance">
          <div className="boost-balance__title text14">{text.balance}</div>
          <div className="boost-balance__wrap">
            <div className="boost-balance__item">
              <img src="img/coin.svg" alt="" />
              <span id="balance-value">{formatNumber(user?.balance)}</span>
            </div>
            <div className="boost-balance__item">
              <span id="balance-rs-value">{formatNumber(user?.balance_rs)}</span>
            </div>
          </div>
          <TonConnectButton className='ton-connect-btn'/>
        </div>
        {/* <script>
          const tonConnectUI = new TON_CONNECT_UI.TonConnectUI({
            manifestUrl: "https://raceofstone.com/tonconnect-manifest.json",
            buttonRootId: "ton-connect",
            uiPreferences: {
              theme: "DARK",
            },
            uiOptions:{
              twaReturnUrl: "https://t.me/race_ston_bot/app",
            },
            language:'ru'
          });
        </script> */}
        <div className="tabs">
          <div className={`tab _tab ${tab === 1 && '_active'}`} data-tab="_tab1" onClick={() => settab(1)}>
            <span>{text.tab_title_upgrades}</span>
          </div>
          <div className={`tab _tab ${tab === 2 && '_active'}`} data-tab="_tab2" onClick={() => settab(2)}>
            <span>{text.tab_title_store}</span>
          </div>
        </div>
          <AnimatePresence mode='wait'>
        {tab === 1 && <motion.div
        key={1}
        initial={{x:-50,opacity:0}}
        animate={{x:0,opacity:1}}
        exit={{x:-50,opacity:0}}
        transition={{duration:0.1}}
        className="tab-content _tab1 _active">
          <div className="boost-upgrades">
            <div className="m-subtitle">{text.daily_boost}</div>
            <div className="boost-upgrades__items">
              <div
                className="m-card boost-card"
                data-modal="chargeModal"
                style={user?.battery === 0 ? {cursor:"pointer",opacity:0.7} : {cursor:"pointer"}}
                id="battery-block"
                onClick={() => user?.battery !== 0 ? setModal({content:<BatteryModal/>}): tg.HapticFeedback.notificationOccurred('error')}
              >
                <div className="m-card__inner">
                  <div className="boost-card__top">
                    <div className="boost-card__icon">
                      <img src="img/boost-icon.png" alt="" />
                    </div>
                    <div className="boost-card__title">{text.time_charge}</div>
                  </div>
                  <div className="boost-card__desc">
                    {text.time_charge_dsc}
                  </div>
                  <div className="boost-card__num">
                    <span id="battery-count">{user?.battery}/3</span>
                  </div>
                </div>
              </div>

              <div
                className="m-card boost-card _shadow"
                style={user?.stone === 0 ? {cursor:"pointer",opacity:0.7} : {cursor:"pointer"}}
                id="stone-block"
                onClick={() => user?.stone !== 0 ? handleStone(): tg.HapticFeedback.notificationOccurred('error')}
              >
                <div className="m-card__inner">
                  <div className="boost-card__top">
                    <div className="boost-card__icon">
                      <img src="img/boost-icon2.png" alt="" />
                    </div>
                    <div className="boost-card__title">{text.get_stone}</div>
                  </div>
                  <div className="boost-card__desc">
                    {text.get_stone_desc}
                  </div>
                  <div className="boost-card__num">
                    <span id="stone-count">{user?.stone}/1</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="boost-quest">
            <div className="m-subtitle">{text.quest_boost}</div>
            <div className="m-card boost-card2" style={{opacity:0.5}}>
              <div className="m-card__inner">
                <div className="boost-card2__top">
                  <div className="boost-card2__items">
                    <div className="boost-card2__item">
                      <div className="boost-card2__icon">
                        <img src="img/arrow-top.svg" alt="" />
                      </div>
                      <span>Game boost </span>
                    </div>
                    <div className="boost-card2__item2">
                      <div className="boost-card2__icon">
                        <img src="img/coin.svg" alt="" />
                      </div>
                      <span>20,000 </span>
                    </div>
                  </div>
                  <button className="boost-card2__btn m-btn m-btn-red">
                    <span>UP</span>
                  </button>
                </div>
                <div className="boost-card2__bottom">
                  <div className="boost-card2__desc">
                    <b>COMING SOON</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>}
          
        {tab === 2 && <motion.div
         key={2}
         initial={{x:50,opacity:0}}
         animate={{x:0,opacity:1}}
         exit={{x:50,opacity:0}}
        transition={{duration:0.1}}
        className="tab-content _tab2 _active">
          <BoostStones/>
        </motion.div>}
        </AnimatePresence>
      </div>
    </div>
   </PageWrapper>
  )
}

const BatteryModal = () => {
  const {setModal,updateUserInfo,locale} = useContext(appContext)

  const handle = async () => {
    const res = await getBattery()
    if(res) {
      await updateUserInfo()
      toast(text.toast_time_upgrade,{icon:(
        <img src="img/battery.png" alt="" className='animate-icon'/>
      )
      })
      setModal(null)
    }
  }
  if(!locale) return null
  const text = locale.boost
  return (
    <div className='battery-modal' style={{padding:'3em 0.75em 1.25em'}}>
    <div className="charge-modal__img">
              <img src="img/battery.png" alt=""/>
            </div>

            <div className="modal__title">{text.time_upgrade_modal_title}</div>

            <div className="charge-modal__desc">
              <div className="text14">{text.time_upgrade_modal_desc}</div>
            </div>

            <div className="charge-modal__price">
              <img src="img/coin.svg" alt=""/>
              <span>{text.time_upgrade_modal_free}</span>
            </div>

            <button className="charge-modal__btn m-btn m-btn-big m-btn-red" 
            onClick={handle}
            id="upgrade-battery-btn">
              <span>{text.get}</span>
            </button>
            </div>
  )
}

export default Boost