import {
  RouterProvider,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import useAppData from "./hooks/use-app-data";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import tg from "utils/tg";
import Navigation from "UI/Navigation";
import Modal from "UI/Modal";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { Bounce, Slide, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const navigation = useNavigate();
  const { AppContext, AppContextValue } = useAppData();
  const element = useRoutes(routes.map(({ route }) => route));
  const location = useLocation();

  const reloadBackButton = () => {
    if (tg.BackButton.isVisible) return false;
    tg.BackButton.onClick(() => {
      navigation(-1);
    });
    tg.BackButton.show();
  };

  useEffect(() => {
    tg.expand();
    tg.disableVerticalSwipes();
    // reloadBackButton();
    tg.bottomBarColor = '#141720'
    tg.headerColor = '#000000'
    // tg.onEvent("viewportChanged", reloadBackButton);
    
  }, []);
  if (!element) return null;
  return (
    <TonConnectUIProvider
    manifestUrl={"https://raceofstone.com/tonconnect-manifest.json"}
  >
    <AppContext.Provider value={AppContextValue}>
      <AnimatePresence mode="sync">
        {React.cloneElement(element, { key: location.pathname })}
      </AnimatePresence>
      <Navigation />
      <Modal/>
      <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      closeButton={false}
      transition={Slide}
      theme="dark"
      />
    </AppContext.Provider>
    </TonConnectUIProvider>
  );
}

export default App;
