import { QRCodeSVG } from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import PageWrapper from 'UI/PageWrapper'

type Props = {}
const link = new URL(process.env.REACT_APP_SHARE_LINK || '')
link.searchParams.delete('start')
const Loading = (props: Props) => {
  const [loading, setloading] = useState(0)
  const navigate = useNavigate()
  const [allowed, setallowed] = useState(false)
  useEffect(() => {
    if(!allowed) return
    if(loading === 100) {
      navigate('/training')
      return
    }
    setTimeout(() => {
        setloading(prev => prev+1)
    }, 20);
  }, [loading,allowed])
  useEffect(() => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
    if(isMobileDevice) setallowed(true)
  }, [])
  return (
    <PageWrapper className='m-page' style={{overflow:'hidden'}}>
      <div className="preloader">
        <img src="img/logo.svg" alt="" className="preloader__logo"/>
        <div className="preloader__content">
            <img src="img/preloader.jpg" alt="" className={`preloader__img ${!allowed && 'qr-showed'}`}/>
            {!allowed && <div className='qr-container'>
              <QRCodeSVG value={link.toString()} size={170} level='Q' bgColor='#00000000' fgColor='#d50013' marginSize={4}/>
              </div>}
            {allowed && <div className="loader">
                <div className="loader__num">
                    loading ... <span id="percent">{loading}</span>%
                </div>
                <div className="loader__progress">
                    <span id="percent-progress" style={{width:`${loading}%`}}></span>
                </div>
            </div>}
        </div>
        <div className="preloader__bottom">
            <div className="preloader__text text14">
                {allowed ? 'Official chanels by Proof Ranking' : 'Play Race of Stone on your mobile device!'}
            </div>
            <div className="preloader__wrap">
                <img src="img/logo2.svg" alt="" className="preloader__logo2"/>
                <a href="https://t.me/RaceOfStone" className="preloader__link">
                    <img src="img/youtube.svg" alt=""/>
                </a>
                <a href="https://t.me/RaceOfStone" className="preloader__link">
                    <img src="img/tg.svg" alt=""/>
                </a>
                <a href="https://t.me/RaceOfStone" className="preloader__link">
                    <img src="img/x.svg" alt=""/>
                </a>
            </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default Loading