import React, { useEffect, useRef, useState } from "react";
import GameStats from "./components/GameStats";
import GameStones from "./components/GameStones";
import GameField from "./components/GameField";
import GameContext from "utils/game-context";
import getUserData from "api/get-user-data";
import { Stone } from "types/user-data";
import useTime from "hooks/use-time";
import GameState from "types/game-state";
import tg from "utils/tg";
import useAnimatedReward from "hooks/use-animated-reward";
import finishGame from "api/finish-game";
import AsteroidExplosionResult from "types/asteroid-explosion-result";
import Balance from "types/balance";
import GameLoading from "./components/GameLoading";
import GameModal from "./components/GameModal";
import getAsteroidExplosions from "api/get-asteroid-explosions";
import './game.scss'

const Game = () => {
  const [stones, setstones] = useState<Stone[] | null>(null);
  const [coins, setCoins] = useState<Balance>({local:0,remote:0});
  const [gameState, setgameState] = useState<GameState>('finish')
  const [modal, setmodal] = useState(true)
  const [combo, setCombo] = useState(0)
  const [loading, setloading] = useState(false)
  const asteroidExplosionsCount = useRef(0)
  let stonesCounter = useRef<Partial<Record<Stone['alias'],number>>>({}).current
  const [asteroidExplosions, setAsteroidExplosions] = useState<AsteroidExplosionResult[]>([])
  const {items,spawnAnimatedReward} = useAnimatedReward()
  const {time, pauseTimer, isPaused,startTimer } = useTime(90000,false);
  const [selectedStone, setselectedStone] = useState<Stone | null>(null);
  const [availableGames, setavailableGames] = useState(0)

  const popAsteroidExplosions = (count: number): AsteroidExplosionResult[] => {
    if (asteroidExplosions.length === 0) return [];
  
    // Извлекаем нужное количество элементов (или все оставшиеся, если их меньше)
    const poppedExplosions = asteroidExplosions.slice(0, count);
    const restExplosions = asteroidExplosions.slice(count);
  
    // Обновляем состояние, удаляя извлеченные элементы
    setAsteroidExplosions(restExplosions);
    poppedExplosions.filter(({stone}) => stone).forEach(({stone}) => {
      if(!stone) return
      stonesCounter[stone] = (stonesCounter[stone] || 0) + 1
    })
    asteroidExplosionsCount.current += poppedExplosions.length
    return poppedExplosions;
  };
  
  

  useEffect(() => {
    tg.disableVerticalSwipes();
    tg.BackButton.onClick(() => window.location.href = window.location.origin + '/home.html')
    tg.BackButton.show()
    tg.enableClosingConfirmation()
    getUserData().then((res) => {
      if(!res) {
        tg.showAlert(`It was not possible to get your data`)
      } else if('error' in res){
        tg.showAlert(`Get user data error: ${JSON.stringify(res)}`)
      } else if (res && 'stones' in res) {
        setstones(res?.stones);
        setavailableGames(res.available_games)
        setgameState(res.available_games ? 'ready' : 'not-available')
        // setgameState('ready')
      } 
    });
    // eslint-disable-next-line no-restricted-globals
    (screen.orientation as any)?.lock("portrait").catch((error: Error) => {
      console.error("Orientation lock failed:", error);
    });
  }, []);

  useEffect(() => {
    if(time === 0 && gameState !== 'finish') {
      setgameState('finish')
      setloading(true)
      finishGame(coins.local,asteroidExplosionsCount.current,coins.remote,combo,stonesCounter).then(res => {
        if(res) setavailableGames(res.available_games)
          else tg.showAlert(`Fihish game error: ${JSON.stringify(res)}`)
      }).finally(() => {
        setloading(false)
      })
    }
  }, [time])
  useEffect(() => {
    // if(availableGames == 0) return
    if (asteroidExplosions.length < 30) {
      // Генерация недостающих объектов
      getAsteroidExplosions().then(res => {
        if(res && Array.isArray(res)) {
          setAsteroidExplosions((prevExplosions) => [...prevExplosions, ...res]);
        } else if(res?.error) setgameState('not-available')
        else tg.showAlert('Asteroids loading error!')
      })
    }
  }, [asteroidExplosions]);
  
  if(gameState === 'loading') return null
  const GameContextValue = {
    selectedStone,
    setselectedStone,
    stones,
    setstones,
    coins,
    setCoins,
    pauseTimer,
    spawnAnimatedReward,
    stonesCounter,
    isPaused,
    time,
    combo,
    popAsteroidExplosions,
    setCombo,
    modal,
    setmodal,
    availableGames,
    gameState,
    setgameState,
    startTimer
  }
  return (
    <div className="App">
      <GameContext.Provider
        value={GameContextValue}
      >
        <GameStats />
        <GameField />
        <GameStones />
        <GameModal finishLoading={loading}/>
        {items}
        <GameLoading/>
      </GameContext.Provider>
    </div>
  );
};

export default Game;
