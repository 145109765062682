import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    icon:keyof typeof icons
}

const Icon = (props: Props) => {
  const icon = icons[props.icon]
//   icon.props = props
return icon
}

const icons = {
  rs:(
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1080_19335)">
    <circle cx="13" cy="13" r="10" fill="#D50013"/>
    </g>
    <g filter="url(#filter1_di_1080_19335)">
    <ellipse cx="7.33883" cy="7.39039" rx="7.33883" ry="7.39039" transform="matrix(0.965399 -0.260778 0.256873 0.966445 3.93567 7.77124)" fill="url(#paint0_radial_1080_19335)"/>
    <path d="M19.9556 11.0991C20.997 15.0174 18.6904 19.0443 14.8045 20.0939C10.9186 21.1436 6.92378 18.8189 5.88233 14.9006C4.84089 10.9823 7.14751 6.95544 11.0334 5.90576C14.9193 4.85608 18.9142 7.18081 19.9556 11.0991Z" stroke="#CD2900" stroke-width="0.1"/>
    </g>
    <g filter="url(#filter2_di_1080_19335)">
    <path d="M10.0428 11.0493V12.8979H10.9405C11.4654 12.8979 11.7727 12.5483 11.7727 11.9736C11.7727 11.4109 11.4481 11.0493 10.9302 11.0493H10.0428ZM10.0428 13.778V15.8999H9V10.1008H11.0407C12.187 10.1008 12.8431 10.8041 12.8431 11.9495C12.8431 12.6929 12.5116 13.3359 11.9384 13.5971L12.9881 15.8999H11.8072L10.8784 13.778H10.0428Z" fill="#E9EAEE"/>
    <path d="M13.4883 14.2478H14.4931C14.5414 14.7261 14.9696 15.0395 15.522 15.0395C16.0779 15.0395 16.4578 14.7301 16.4578 14.3041C16.4578 13.9263 16.2195 13.7173 15.6187 13.5606L14.9696 13.3958C14.0511 13.1668 13.5988 12.6242 13.5988 11.7763C13.5988 10.7073 14.3929 10 15.5048 10C16.6649 10 17.3935 10.6952 17.4108 11.7441H16.4336C16.3991 11.2539 16.0296 10.9565 15.5082 10.9565C14.9937 10.9565 14.6484 11.2418 14.6484 11.6718C14.6484 12.0295 14.8902 12.2304 15.4633 12.3831L16.0676 12.5318C17.0655 12.781 17.5005 13.2793 17.5005 14.1634C17.5005 15.2887 16.7167 16 15.4806 16C14.2824 16 13.5194 15.3369 13.4883 14.2478Z" fill="#E9EAEE"/>
    </g>
    <defs>
    <filter id="filter0_i_1080_19335" x="3" y="3" width="20" height="20" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="0.3" operator="erode" in="SourceAlpha" result="effect1_innerShadow_1080_19335"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="0.5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1080_19335"/>
    </filter>
    <filter id="filter1_di_1080_19335" x="0.582275" y="0.60376" width="24.6733" height="24.7922" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1080_19335"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1080_19335"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1080_19335" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1080_19335"/>
    </filter>
    <filter id="filter2_di_1080_19335" x="5" y="10" width="16.5005" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1080_19335"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1080_19335" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1080_19335"/>
    </filter>
    <radialGradient id="paint0_radial_1080_19335" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.33883 7.39039) rotate(0.383393) scale(7.33696 7.77716)">
    <stop stop-color="#D80000"/>
    <stop offset="1" stop-color="#9B000E"/>
    </radialGradient>
    </defs>
    </svg>
    )
}

export default Icon