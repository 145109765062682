import React, { useContext, useState } from 'react'
import appContext from 'utils/app-context'
import tg from 'utils/tg'

type Props = {}

function HomeSetLangModal({}: Props) {
  const {locale,langs,setLanguage,setModal} = useContext(appContext)
  const [loading, setloading] = useState<string | false>(false)
  const [code, setcode] = useState(locale?.code)
  console.log("🚀 ~ HomeSetLangModal ~ code:", code)
  if(!locale) return null
  const text = locale?.home

  const handleClick = async () => {
    console.log("🚀 ~ handleClick ~ code:", code)
    if(!code) return
    console.log("🚀 ~ code.toLowerCase() === locale.code:", code.toLowerCase(), locale.code)
    if(code.toLowerCase() === locale.code) return
    setloading(code)
    const res = await setLanguage(code)
    console.log("🚀 ~ handleClick ~ res:", res)
    if(res) {
      tg.HapticFeedback.notificationOccurred('success')
      setModal(null)
    }
    setloading(false)
  }
  return (
    <div style={{margin:"25px 0"}}>
         <div className="friend-modal__info">
                        <div className="friend-modal__title">
                            {text.lang}
                        </div>
                        <div className="friend-modal__desc">
                        {text.lang_desc}
                        </div>
                    </div>
                    <ul className='lang-list'>
                    {langs?.map(({icon,displayName,code:C}) => {
                      const isCurrent = code === C.toLowerCase()
                      return (
                        <li onClick={() => setcode(C.toLowerCase())} className={`m-card ${isCurrent && 'current'}`}>
                          <div className='m-card__inner'>
                            {icon} {displayName}
                          </div>
                        </li>
                      )
                    })}
                    </ul>
                    <div className="friend-modal__nav">
                       
                        <button className="_close-modal m-btn-red m-btn m-btn-big" onClick={handleClick}>
                            <span>{text.save}</span>
                        </button>
                    </div>
        </div>
  )
  // return (
  //   <div className='battery-modal' style={{padding:'3em 0.75em 1.25em'}}>
  //   <div className="charge-modal__img">
  //             <img src="img/battery.png" alt=""/>
  //           </div>

  //           <div className="modal__title">{text.lang}</div>

  //           <div className="charge-modal__desc">
  //             <div className="text14">{text.lang_desc}</div>
  //           </div>

  //           <button className="charge-modal__btn m-btn m-btn-big m-btn-red" 
  //           onClick={handleClick}
  //           id="upgrade-battery-btn">
  //             <span>{loading ? <img className="loading-icon" src="img/loader.svg" alt="" /> : text.save}</span>
  //           </button>
  //           </div>
  // )
}

export default HomeSetLangModal