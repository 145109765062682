import GridItem, { ItemType } from "types/grid-item";

export default (columns:number,rows:number): GridItem[][] => {
  const grid: GridItem[][] = Array.from({ length: rows }, () =>
    Array.from({ length: columns }, () => ({
      id: generateId(),
      type: "type1" as ItemType,
    }))
  );

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < columns; col++) {
      const type = getValidItem(grid, row, col);
      grid[row][col] = { id: generateId(), type };
    }
  }

  return grid;
};

export const generateId = () => Math.random().toString(36).substr(2, 9);

// Функция для проверки совпадений по горизонтали или вертикали
const isMatching = (
  grid: GridItem[][],
  row: number,
  col: number,
  type: ItemType
): boolean => {
  const isHorizontalMatch =
    col >= 2 &&
    grid[row][col - 1].type === type &&
    grid[row][col - 2].type === type;
  const isVerticalMatch =
    row >= 2 &&
    grid[row - 1][col].type === type &&
    grid[row - 2][col].type === type;
  return isHorizontalMatch || isVerticalMatch;
};

// Генерация элемента, который не приведет к совпадению
const getValidItem = (
  grid: GridItem[][],
  row: number,
  col: number
): ItemType => {
  let itemType: ItemType;
  do {
    itemType = getRandomItem();
  } while (isMatching(grid, row, col, itemType));
  return itemType;
};

export const getRandomItem = (): ItemType => {
  const randomIndex = Math.floor(Math.random() * 6) + 1;
  return `type${randomIndex}` as ItemType;
};
