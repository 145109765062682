import { useState } from "react";
import { motion } from "framer-motion";
import { Stone } from "types/user-data";

export default () => {
  const [items, setItems] = useState<JSX.Element[]>([]);

  const spawnAnimatedReward = (type: Stone['alias'] | number, position: { x: number, y: number },isRS:boolean,onEnd?:() => void) => {
    let targetElem: HTMLElement | null = null;

    if (typeof type === 'string') {
      // Ищем элемент для камня
      targetElem = document.getElementById(type);
    } else if (isRS) {
      // Ищем элемент для баланса
      targetElem = document.getElementById('rs-display');
    } else if (typeof type === 'number') {
      // Ищем элемент для баланса
      targetElem = document.getElementById('balance-display');
    }

    if (!targetElem) return;

    // Получаем финальную позицию, куда элемент должен полететь
    const targetRect = targetElem.getBoundingClientRect();
    const finalPosition = {
      x: targetRect.left,
      y: targetRect.top
    };

    // Получаем размеры оригинального элемента
    const { width, height } = targetRect;

    // Копируем оригинальный элемент
    const clonedElem = targetElem.cloneNode(true) as HTMLElement;
    if(typeof type === 'number') clonedElem.textContent = String(type)
    // Создаем JSX элемент для анимации, используя копию
    const left = position.x - (width/2)
    const top = position.y - (height/2)
    const newItem = (
      <motion.div
        key={Math.random()} // Уникальный ключ для каждого элемента
        className="animated-reward"
        initial={{ scale: 0, left, top }}
        animate={{
          scale: [0,1,1,1,0],
          left: [left,left,left,finalPosition.x,finalPosition.x],
          top: [top,top,top,finalPosition.y,finalPosition.y]
        }}
        transition={{ duration: 1,ease:'easeInOut' }}
        style={{
          width: `${width}px`, // Применяем ширину оригинала
          height: `${height}px`, // Применяем высоту оригинала
        }}
        onAnimationComplete={() => {
          // Удаляем элемент после завершения анимации
          if(onEnd) onEnd()
          setItems(prevItems => prevItems.filter(item => item.key !== newItem.key));
        }}
      >
        {/* Вставляем содержимое оригинального элемента */}
        <div dangerouslySetInnerHTML={{ __html: clonedElem.outerHTML }} />
      </motion.div>
    );

    // Добавляем новый элемент в массив
    setItems(prevItems => [...prevItems, newItem]);
  };

  return {
    items,
    spawnAnimatedReward,
  };
};
