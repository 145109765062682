import React, { useContext, useEffect, useRef, useState } from "react";
import appContext from "utils/game-context";
import tg from "utils/tg";
import { Stone } from "types/user-data";
import { motion } from "framer-motion";
import stonesInfo from "utils/stones-info";
import stoneUse from "utils/stone-use";

const GameStones = () => {
  const {setselectedStone,stones,pauseTimer,setstones,selectedStone,stonesCounter,isPaused} = useContext(appContext)
  const [stonesState, setstonesState] = useState<Record<Stone['alias'],Stone['active']>>()

  useEffect(() => {
    if(!stones || stonesState) return
    setstonesState(stones.reduce((acc,stone) => {
      acc[stone.alias] = stone.active
      return acc
    },{} as Record<Stone['alias'],Stone['active']>))
  }, [stones])
  return (
    <div className="game-nav">
      {stones && stones.map((stone) => {
        const {image,shadow,timeStop} = stonesInfo[stone.alias]
        let animate = false
        if(stonesState && stonesState[stone.alias] === 0 && stone.active) {
          animate = true
        }
        const isCurrentSelected = selectedStone?.alias === stone.alias
        return (
          <div
          key={stone.alias}
          style={{filter:stone.active ? '' : 'grayscale(1) brightness(0.5)'}}
          onClick={() => {
            if(isCurrentSelected) {
              setselectedStone(null)
              return
            }
            if (stone.active && stone.quantity) {
            // if (true) {
              if(timeStop) {
                if(isPaused) {
                  tg.HapticFeedback.notificationOccurred('warning')
                  return
                }
                pauseTimer(timeStop)
                stoneUse(setstones,stone,stonesCounter)
                return
              }
              setselectedStone(stone)
            } else tg.HapticFeedback.notificationOccurred('error')
          }}
            className={`game-nav__btn m-card ${isCurrentSelected && 'current'}`}
            data-stone="stone_red"
            id="stoneButton"
          >
            <motion.div 
            animate={{y:animate ? [0,-50,0] : 0}}
            onAnimationEnd={() =>{
              if(animate) setstonesState(prev => {
                if(!prev) return prev
                prev[stone.alias] = 1
                return prev
              })
            }}
            className="m-card__inner">
              {(timeStop && typeof isPaused === 'number') && <CircularTimer seconds={isPaused}/>}
              <img
                id={stone.alias}
                className="game-nav__icon"
                src={image}
              />
              <div
                className="game-nav__shadow"
                style={{ background: shadow }}
              ></div>
            </motion.div>
            <div 
            style={(timeStop && typeof isPaused === 'number') ? {display:'none'} : {}}
            className="game-nav__num m-card">
              <div className="m-card__inner">
                <span className="text12">{stone.active ? stone.quantity : null}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GameStones;


interface CircularTimerProps {
  seconds: number; // начальное количество секунд
}

const CircularTimer: React.FC<CircularTimerProps> = ({ seconds }) => {
  console.log("🚀 ~ seconds:", seconds)
  const allTime = useRef(seconds).current
  console.log("🚀 ~ allTime:", allTime)


 
  // Рассчитываем угол, чтобы заполнить сектор (в градусах)
  const getProgressAngle = () => {
    const progress = (seconds / allTime) * 360;
    console.log("🚀 ~ getProgressAngle ~ progress:", progress)
    return progress;
  };

  // Функция для получения пути для заполнения сектора
  const getPiePath = () => {
    const radius = 18; // радиус круга
    const angle = getProgressAngle();
  
    if (angle === 360) {
      // Если круг полностью заполнен, возвращаем полный круг
      return `M 18 18 m -18 0 a 18 18 0 1 0 36 0 a 18 18 0 1 0 -36 0`;
    }
  
    const x = Math.cos((angle - 90) * (Math.PI / 180)) * radius;
    const y = Math.sin((angle - 90) * (Math.PI / 180)) * radius;
  
    const largeArcFlag = angle > 180 ? 1 : 0;
  
    return `M 18 18 L 18 0 A 18 18 0 ${largeArcFlag} 1 ${18 + x} ${18 + y} Z`;
  };

  return (
    <div className="progress-circle">
      <svg viewBox="0 0 36 36" className="circular-chart">
        <path className="circle-fill" d={getPiePath()} />
      </svg>
    </div>
  );
};
