import claimDailyReward from "api/claim-daily-reward";
import doneTask from "api/done-task";
import React, { useContext, useEffect, useRef, useState } from "react";
import Task from "types/task";
import TasksResponce from "types/task-response";
import TimerText from "UI/TimerText";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import formatTime from "utils/format-time";
import tg from "utils/tg";

type Props = {
  daily?: TasksResponce["everyday"];
  onTimerEnd: () => void;
};

const TaskCardDaily = ({ daily, onTimerEnd }: Props) => {
  const { setModal } = useContext(appContext);
  if (!daily) return null;

  const { available, current_stage, seconds, stages } = daily;

  const open = () => setModal({ content: <DailyModal daily={daily} onget={onTimerEnd}/> });
  return (
    <div className="m-card task-card">
      <div className="m-card__inner">
        <div className="task-card__wrap">
          <div className="task-card__items">
            <div className="task-card__item">
              <div className="task-card__icon">
                <img src="img/task-icon.svg" alt="" />
              </div>
              <span className="text14 bold-text">Ежедневный бонус</span>
            </div>
            <div className="task-card__item">
              <div className="task-card__icon">
                <img src="img/coin.svg" alt="" />
              </div>
              <span className="text14">
                +{formatNumber(stages[current_stage])}
              </span>
            </div>
          </div>
          {available ? (
            <button className="task-card__btn m-btn m-btn-red" onClick={open}>
              <span>GO</span>
            </button>
          ) : (
            <button
              className="task-card__btn task-card__btn2 m-btn"
              data-modal="bonusModal"
              id="get-daily-reward-btn"
            >
              <TimerText time={seconds} onEnd={onTimerEnd} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
type DailyModalProps = {
  daily: TasksResponce["everyday"];
  onget:() => void
};
const DailyModal = ({
  daily: { available, current_stage, seconds, stages }, onget
}: DailyModalProps) => {
  const [position, setposition] = useState(0)
  const {updateUserInfo,setModal,locale} = useContext(appContext)
  const handle = async () => {
    const res = await claimDailyReward()
    if(res?.success) {
      onget()
      updateUserInfo()
      setModal(null)
    }
  }

  useEffect(() => {
    setposition(current_stage * 154)
  }, [])
  if(!locale) return null
  const text = locale.tasks
  return (
    <div style={{ margin: "30px 0" }}>
      <div className="bonus-modal__top">
        <div className="bonus-modal__info">
          <div className="bonus-modal__title">{text.daily_bonus}</div>
          <div className="bonus-modal__desc" dangerouslySetInnerHTML={{__html:text.daily_desc_html}}/>
        </div>
        <div className="bonus-modal__items" id="daily-reward-icons">
          {stages.map((_, i) => (
            <div
              className={`bonus-modal__item ${
                current_stage >= i && "bonus-modal__item--red"
              }`}
            />
          ))}
        </div>
      </div>

      <div className="bonus-slider" style={{transform:'translateX(calc(50% - 79px))'}}>
        <div className="bonus-slider-track" style={{transform:`translateX(${-position}px)`}}>
          {stages.map((n, i) => (
            <div
              className="bonus-card m-card"
              style={current_stage === i ? { background: "#d50013" } : {}}
            >
              <div className="m-card__inner">
                <img src="img/coin.svg" alt="" className="bonus-card__icon" />
                <div className="bonus-card__num">+{formatNumber(n)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <button
        className="bonus-modal__btn m-btn m-btn-big m-btn-red"
        id="daily-reward-btn"
        onClick={handle}
      >
        <span>{text.get}</span>
      </button>
    </div>
  );
};

export default TaskCardDaily;
